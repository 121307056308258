import React, { useState } from 'react';
import { Document, Text, Page, StyleSheet, Image, View, Svg } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

import Logo from '../../media/HOME-02.png'



const styles = StyleSheet.create({
    page: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: "flex-start",
        alignItems: 'center',
        padding: '40',
        minWidth: '100%'
    },
    imageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20',
    },
    image: {
        width: '50%',
    },
    sectionRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        marginBottom: '10',
    },
    sectionRow2: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginBottom: '10',
    },
    sectionRow3: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: 'flex-start',
        marginBottom: '10',
        minWidth: '75%'
    },
    sectionColumn: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        marginBottom: '10',
    },
    sectionColumn2: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginBottom: '10',
    },
    parrafo: {
        fontSize: '14',
        textAlign: "justify",
        lineHeight: '1.5',
    },
    title1: {
        fontSize: '14',
        fontWeight: 'bold',
        fontWeight: '900',
        marginBottom: '5',
        textAlign: 'center'
    },
    title2: {
        fontSize: '11',
        fontWeight: 'bold',
        marginBottom: '5',
        textAlign: 'center'
    },
    subtitle: {
        fontSize: '8',
        fontWeight: 'bold',
        marginBottom: '0',
        textAlign: 'center'
    },
    table: {
        display: "table",
        width: "100%",
        borderStyle: "solid",
        // borderWidth: 1,
        // borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
    },
    tableCol: {
        width: "25%", // Esto es para dividir el ancho entre 4 columnas
        // borderStyle: "solid",
        // borderWidth: 1,
        // borderLeftWidth: 0,
        borderBottomWidth: 1,
    },
    tableCell1: {
        margin: "auto",
        marginTop: 5,
        fontSize: 14,
    },
    tableCell2: {
        margin: "auto",
        marginTop: 5,
        fontSize: 12,
    },
    fixedTop: {
        top: 0,
        left: 0,
        width: '100%',
        padding: 10,
        position: 'fixed',
        textAlign: 'center'
    },
    fixedBottom: {
        bottom: 0,
        left: 0,
        width: '100%',
        padding: 10,
        position: 'absolute',
        textAlign: 'center',
        marginLeft: 35
    },
});

export const FacturaPDF = ({ pedido }) => {

    function formatDate(dateString) {
        // Crear un objeto Date a partir del string de fecha
        const date = new Date(dateString);

        // Extraer las partes necesarias
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        // Formatear la fecha en la forma deseada
        return `${day}/${month}/${year} - ${hours}:${minutes}`;
    }


    return (
        <PDFViewer style={{ minHeight: '86vh' }}>
            <Document title={"57Grupo"} producer='JC Informatics'>
                {console.log(pedido)}
                <Page size="A4" style={styles.page}>
                    <View style={styles.fixedTop} fixed>
                        <View style={styles.imageContainer}>
                            <Image source={Logo} style={styles.image} />
                        </View>

                        <View style={styles.sectionRow2}>
                            <Text style={styles.title1}>INDUSTRIAL 57 GROUP IMPORT & EXPORTS S.L. - B12988671</Text>
                        </View>

                        <View style={styles.sectionRow}>
                            <div style={{ minWidth: '45%', maxWidth: '45%' }}>
                                <View style={styles.sectionRow}>
                                    <Text style={styles.title2}>Nº PEDIDO: {pedido.idPedido}</Text>
                                </View>
                                <View style={styles.sectionRow}>
                                    <Text style={styles.title2}>Fecha: {formatDate(pedido.fecha)}</Text>
                                </View>
                                <View style={styles.sectionRow}>
                                    <Text style={styles.title2}>Nº Cliente: {pedido.idCliente}</Text>
                                </View>
                            </div>

                            <div style={{ minWidth: '45%', maxWidth: '45%' }}>
                                <View style={styles.sectionRow}>
                                    <Text style={styles.title2}>Cliente: {pedido.idCliente}</Text>
                                </View>
                                <View style={styles.sectionRow}>
                                    <Text style={styles.title2}>Fecha: {formatDate(pedido.fecha)}</Text>
                                </View>
                                <View style={styles.sectionRow}>
                                    <Text style={styles.title2}>Nº Cliente: {pedido.idCliente}</Text>
                                </View>
                            </div>
                        </View>
                    </View>


                    <View style={styles.sectionColumn}>
                        <Text style={styles.title1}>
                            Articulos
                        </Text>
                    </View>

                    <div>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell1}>Nº</Text>
                                </View>
                                {/* <View style={styles.tableCol}>
                                    <Text style={styles.tableCell1}>ID</Text>
                                </View> */}
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell1}>Nombre</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell1}>Cantidad</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell1}>Precio</Text>
                                </View>
                            </View>
                        </View>

                        {pedido.productosPedido.map((productos, index) => (
                            <div>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell2}>{index + 1}</Text>
                                        </View>
                                        {/* <View style={styles.tableCol}>
                                            <Text style={styles.tableCell2}>{productos.id}</Text>
                                        </View> */}
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell2}>{productos.nombre}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell2}>{productos.cantidad}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell2}>{productos.precio}€</Text>
                                        </View>
                                    </View>
                                </View>
                                <div className="separador"></div>
                            </div>
                        ))}
                    </div>




                    <View style={styles.fixedBottom} fixed>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell2}></Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell1}>Importe</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell1}>I.V.A.</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell1}>TOTAL</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell2}></Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell2}>{(pedido.precioTotal / 1.21).toFixed(2)}€</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell2}>21%</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell2}>{pedido.precioTotal}€</Text>
                                </View>
                            </View>
                        </View>


                        {/* <View style={styles.sectionColumn2}> */}
                        <div
                        // style={{ minWidth: '100%', marginTop: '10em'  }}
                        >
                            <View style={styles.sectionRow}>
                                <Text style={styles.title2}>POLÍTICA DE DEVOLUCIÓN</Text>
                            </View>
                            <View style={styles.sectionRow}>
                                <Text style={styles.subtitle}>- NO SE ADMITEN DEVOLUCIONES PASADOS 15 DIAS DE LA FECHA DE ALBARA</Text>
                            </View>
                            <View style={styles.sectionRow}>
                                <Text style={styles.subtitle}>- NO SE ADMITEN DEVOLUCIONES SIN AUTORIZACION PREVIA.</Text>
                            </View>
                            <View style={styles.sectionRow}>
                                <Text style={styles.subtitle}>- SERA NECESARIO SOLICITAR Y PRESENTAR UN FORMULARIO DE DEVOLUCIÓN.</Text>
                            </View>
                            <View style={styles.sectionRow}>
                                <Text style={styles.subtitle}>- SE APLICARA UN CARGO DEL 20% (POR GASTOS DE PREPARACION, EMBALAJE Y TRANSPORTE) PARA DEVOLUCIONES POR MOTIVOS NO IMPUTABLES A INDUSTRIAL 57</Text>
                            </View>
                        </div>
                        {/* <View style={styles.imageContainer}>
                            <Image source={Logo} style={styles.image} />
                        </View> */}

                        <View style={styles.sectionRow}>
                            <Text style={styles.subtitle}>Inscrita en el registro mercantil de Castellón, Tomo 1732, Libro 1293, Sección 0, Folio 97, Hoja CS-39998, inscripción 1, CIF:B12988671</Text>
                        </View>
                        {/* </View> */}
                    </View>



                </Page>
            </Document>
        </PDFViewer >
    );
};
