import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';

import Img from '../media/icons/tuerca.png';


import { useNavigate } from 'react-router-dom';

export const CardCat = ({ info, index }) => {

    const Navigate = useNavigate();
    const [timeout, setTimeout] = useState(index * 100);
    const [img, setImg] = useState('');


    useEffect(() => {
        setImg(info.imagen);
    }, [])

    return (
        <Grow in={true} style={{ transformOrigin: '0 0 0' }}
            {...(true ? { timeout: timeout } : {})}>
            <Grid
                container
                direction="column"
                justifyContent="end"
                alignItems="start"
                xs={10} sm={3} md={3} xl={2}
                className='categoria-card sombra-corta'
                onClick={() => Navigate("/categoria/" + info.idCategoria)}
                key={index}
                style={{
                    backgroundImage: `linear-gradient(to top, rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0)), url(${img})`,
                    backgroundSize: 'cover', // Para que la imagen cubra todo el contenedor
                    backgroundPosition: 'center', // Centrar la imagen
                    height: 'auto', // Ajusta el alto como sea necesario
                    width: '100%', // O ajusta el ancho según tu diseño
                    textAlign: 'left'
                }}
            >
                {/* <img className='drop' src={Img} alt="" style={{ maxWidth: '35%' }} /> */}
                <h3 className='no-margen drop'>{info.nombre}</h3>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={3}
                >
                    <div className="separador-3"></div>

                </Grid>
            </Grid>
        </Grow>


    )
}
