
import React, { useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';

export const DatosUsu = ({ datosUsu }) => {

    const theme = createTheme({
        palette: {
            cyan: {
                main: '#47B8C2',
                light: '#47B8C2',
                dark: '#47B8C2',
                contrastText: '#EEEEEE',
            },
        },
    });

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            className='load'
            style={{ padding: '1em', borderBottomRightRadius: '5px', borderTopRightRadius: '5px' }}
        >
            <ThemeProvider theme={theme}>
                <Collapse in={true}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                    >
                        <h2>Mis datos</h2>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        gap={2}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                            gap={1}
                        >
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                xs={12}
                                gap={1}
                            >
                                <strong>Nombre</strong>
                                <TextField disabled id="standard-basic" label={datosUsu.nombre} variant="filled" size='small' color='cyan' style={{minWidth: '100%'}}/>
                            </Grid>
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                xs={12}
                                gap={1}
                            >
                                <strong>Apellidos</strong>
                                <TextField disabled id="standard-basic" label={datosUsu.apellidos} variant="filled" size='small' color='cyan' style={{minWidth: '100%'}}/>
                            </Grid>
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                xs={12}
                                gap={1}
                            >
                                <strong>Telefono</strong>
                                <TextField disabled id="standard-basic" label={datosUsu.tlfCliente} variant="filled" size='small' color='cyan' style={{minWidth: '100%'}}/>
                            </Grid>
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                xs={12}
                                gap={1}
                            >
                                <strong>E-mail</strong>
                                <TextField disabled id="standard-basic" label={datosUsu.email} variant="filled" size='small' color='cyan' style={{minWidth: '100%'}}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Collapse>
            </ThemeProvider>
        </Grid>
    )
}
