import React, { useEffect, useState } from 'react'
import { styled, alpha } from '@mui/material/styles';
import { redirect, useNavigate, useParams } from 'react-router-dom';


import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DirectionsIcon from '@mui/icons-material/Directions';

import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';


import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#E8E8E8',
    transition: '0.5s',
    '&:hover': {
        backgroundColor: '#FFF',
        color: '#1A1A1A',
        transition: '0.5s',
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));


export const Buscar = () => {

    const Navigate = useNavigate();

    const [buscar, setBuscar] = useState("")


    const handledOnChange = (event) => {
        setBuscar(event.target.value);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            // Aquí va tu lógica cuando se presiona "Enter"
            console.log('Enter pressed');
            // Por ejemplo, puedes llamar a otra función aquí
            Navigate("/productos/" + buscar)
        }
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            xs={12} sm={12} md={3}
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={12} sm={12} md={12}
            >
                <Paper
                    component="form"
                    sx={{ p: '0px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                    className='buscador'
                    elevation={0}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Buscar..."
                        inputProps={{ 'aria-label': 'buscar' }}
                        onKeyPress={handleKeyPress}
                        value={buscar}
                        onChange={handledOnChange}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => Navigate("/productos/" + buscar)}>
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </Grid>
        </Grid>
    )
}
