import React from 'react'
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

export const ListLoad = () => {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            gap={2}
            minHeight={500}
        >
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
            <Skeleton variant="rounded" width={1000} height={50} />
        </Grid>
    )
}
