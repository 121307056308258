import React, { useEffect, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import Grid from '@mui/material/Grid';



import { Header } from '../componenets/Header';
import { CabeceraTitulo } from '../componenets/CabeceraTitulo';
import { CardSubcat } from '../componenets/CardSubcat';
import { CardLoad } from '../componenets/loads/CardLoad';
import { AtrasBtn } from '../componenets/botonesNav/AtrasBtn';
import { InicioBtn } from '../componenets/botonesNav/InicioBtn';
import { getCategorias, getSubaCategoria, getTitulos } from '../services/apis';

export const Categoria = () => {

    const Navigate = useNavigate();
    const param = useParams();

    const [subcategorias, setSubcategorias] = useState([]);
    const [cargar, setCargar] = useState(false);
    const [titulo, setTitulo] = useState("");

    const cargarTitulo = async () => {
        try {
            const response = await getTitulos(param.categoria)
            setTitulo(response.data[0].nombre);
        } catch (error) {
            console.log("Error al cargar el titulo ", error)
        }
        
    }


    const cargarSubcategorias = async () => {
        try {
            const response = await getSubaCategoria(param.categoria);
            console.log("categoria",response.data)
            setSubcategorias(response.data);
            setCargar(true);
        } catch (error) {
            console.error("error al cargar subcategorias",error)
        }
        
    }

    useEffect(() => {
        cargarTitulo();
        cargarSubcategorias();
    }, [])


    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            className='load'
        >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={12}
                className='load'
            >
                <Header></Header>
                <CabeceraTitulo></CabeceraTitulo>


                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    xs={11} sm={9} md={11} xl={10}
                    style={{ textAlign: 'center' }}
                >
                    <AtrasBtn></AtrasBtn>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={4} sm={6}
                    >
                        <h2>{titulo}​</h2>
                    </Grid>
                    <InicioBtn></InicioBtn>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                xs={12}
                sx={{ minHeight: '70vh' }}
            >
                {cargar === true && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        style={{ gap: '1em', marginBottom: '25em' }}
                    >
                        {subcategorias.length > 0 ? subcategorias.map((info, index) => (
                            <CardSubcat info={info} index={index}></CardSubcat>
                        )) : <h2>No hay subcategorias :( </h2>}
                    </Grid>
                )}

                {cargar === false && (
                    <CardLoad></CardLoad>
                )}

            </Grid>
        </Grid >



    )
}
