import React, { useEffect, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import Grid from '@mui/material/Grid';

import PersonIcon from '@mui/icons-material/Person';
import InventoryIcon from '@mui/icons-material/Inventory';

import { Header } from '../componenets/Header';
import { DatosUsu } from '../componenets/usuario/DatosUsu';
import { PedidosUsu } from '../componenets/usuario/PedidosUsu';
import { AtrasBtn } from '../componenets/botonesNav/AtrasBtn';
import { InicioBtn } from '../componenets/botonesNav/InicioBtn';
import { getPedidosUsuario, getUserData } from '../services/apis';

export const Usuario = () => {

    const Navigate = useNavigate();


    const [mostrarDatos, setMostrarDatos] = useState(true);
    const [mostrarPedidos, setMostrarPedidos] = useState(false);
    const [datosUsu, setDatosUsu] = useState({});
    const [pedidos, setPedidos] = useState({});
    const [productosPedido, setProductosPedido] = useState([{}]);


    const [margen, setMargen] = useState('19em');

    const getDatosUsu = async () => {
        try {
            const response = await getUserData();
            setDatosUsu(response.data);
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }

    }
    const cargarPedidos = async () => {

        try {
            const response = await getPedidosUsuario();
            console.log(response.data)
            setPedidos(response.data);

        } catch (error) {
            console.log(error);

        }
    }

    useEffect(() => {
        getDatosUsu();
        cargarPedidos();
    }, [])


    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            className='load'
            style={{ marginBottom: margen }}
        >
            <Header></Header>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={12}

            >

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    className='fondo-blanco sombra-larga'
                >
                    <h1>Usuario</h1>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    xs={11} sm={10} md={10} xl={8}
                    style={{ marginTop: '1em' }}
                >
                    <AtrasBtn></AtrasBtn>

                    <InicioBtn></InicioBtn>

                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    xs={11} sm={10} md={10} xl={8}
                    className=' fondo-blanco sombra-larga bordes-redondos'
                    style={{ marginTop: '1em' }}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={12} sm={12} md={12} xl={3}
                    // gap={2}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                            gap={1}
                            className='aprt-usuario'
                            onClick={() => {
                                setMostrarPedidos(false);
                                setMostrarDatos(true);
                                setMargen('14em');
                            }}
                        >
                            <PersonIcon color="cyan" />
                            <h3>Mis Datos</h3>
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                            className='separador'
                        >
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                            gap={1}
                            className='aprt-usuario'
                            onClick={() => {
                                setMostrarPedidos(true);
                                setMostrarDatos(false);
                                setMargen('8em');

                            }}
                        >
                            <InventoryIcon color="cyan" />
                            <h3>Mis Pedidos</h3>
                        </Grid>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                            className='separador'
                        >
                        </Grid>
                    </Grid>


                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={12} sm={12} md={12} xl={9}
                    // className='fondo-gris'
                    >
                        {mostrarDatos && <DatosUsu datosUsu={datosUsu} />}
                        {mostrarPedidos && <PedidosUsu pedidos={pedidos} />}

                    </Grid>

                </Grid>


            </Grid>
        </Grid >
    )
}
