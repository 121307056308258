import React, { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
// import axios from 'axios';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';


// import { Header } from '../componenets/Header';

import { InicioTienda } from '../pages/InicioTienda';
import { Cesta } from '../pages/Cesta';
import { Productos } from '../pages/Productos';
import { Categoria } from '../pages/Categoria';
import { Articulo } from '../pages/Articulo';
// import { PiePagina } from '../componenets/PiePagina';
import { Usuario } from '../pages/Usuario';
import { Subcategorias } from '../pages/Subcategorias';
// import { PedidoFinal } from '../pages/PedidoFinal';
import { Login } from '../pages/Login';
import PrivateRoute from './PrivateRoute';

const theme = createTheme({
    palette: {
        cyan: {
            main: '#47B8C2',
            light: '#47B8C2',
            dark: '#47B8C2',
            contrastText: '#EEEEEE',
        },
    },
});

export const Router = () => {

    //global
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

   


    return (
        <ThemeProvider theme={theme}>
            <div className='fuente load'>
                <Routes>
                    <Route element={<PrivateRoute />}>
                        <Route path='*' element={<InicioTienda />} ></Route>
                        <Route path='/' element={<InicioTienda />} ></Route>
                        <Route path='/inicio' element={<InicioTienda />} ></Route>
                        <Route path='/productos/:busqueda' element={<Productos />} ></Route>
                        <Route path='/usuario' element={<Usuario />} ></Route>
                        <Route path='/categoria/:categoria' element={<Categoria />} ></Route>
                        <Route path='/subcategoria/:subcategoria' element={<Subcategorias />} ></Route>
                        <Route path='/articulo/:idProducto' element={<Articulo />} ></Route>
                        <Route path='/cesta' element={<Cesta />} ></Route>
                        {/* <Route path='/finalizar-pedido' element={<PedidoFinal />} ></Route> */}
                    </Route>
                    {/* //global */}
                    <Route path="/login" element={<Login username={username} setUsername={setUsername} password={password} setPassword={setPassword} />}></Route>
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                {/* <PiePagina></PiePagina> */}
            </div>
        </ThemeProvider>
    )
}
export default Router
