import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Grid from '@mui/material/Grid';

import { Header } from '../componenets/Header';
import { CabeceraTitulo } from '../componenets/CabeceraTitulo';
import { CardCat } from '../componenets/CardCat';
import { CardLoad } from '../componenets/loads/CardLoad';
import { getCategorias } from '../services/apis';

export const InicioTienda = () => {

    const Navigate = useNavigate();


    const [categorias, setCategorias] = useState([]);
    const [cargar, setCargar] = useState(false);

    const cargarCategorias = async () => {
        try {
            const response = await getCategorias();
            console.log("aa", response.data)
            setCategorias(response.data)
            setCargar(true);
        } catch (error) {
             console.error("Error al añadir el cliente:", error);
        }
       
    }



    useEffect(() => {
        cargarCategorias();
    }, [])

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            className='load'
            style={{ marginBottom: '2em' }}
        >
            <Header></Header>
            <CabeceraTitulo></CabeceraTitulo>
            {/* <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={12}
            >
                <h2>Categorias​</h2>
            </Grid> */}
            {cargar === true && (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    gap={2}
                    style={{ marginTop: '2em'}}
                >
                    {categorias.map((item, index) => (
                        <CardCat info={item} index={index}></CardCat>
                    ))}
                </Grid>
            )}


            {cargar === false && (
                <CardLoad></CardLoad>
            )}
            {/* <CardList data={categorias} itemsPerPage={25} ></CardList> */}
        </Grid>

    )
}
