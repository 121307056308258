import React from 'react'
import { useNavigate } from 'react-router-dom';


import Grid from '@mui/material/Grid';

import Logo from '../media/HOME-01.png'


export const HeaderNoLog = () => {
    const Navigate = useNavigate();


    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            className='fondo-azul load sombra-corta'
            style={{ padding: '1em' }}
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={12}
                style={{ gap: '0.5em' }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={12} sm={8} md={6} xl={5}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={10} sm={10} md={11} xl={5}
                        className='no-select pointer blur'
                        onClick={() => Navigate("/")}
                    >
                        <img src={Logo} alt="" style={{ maxWidth: '100%' }} />
                    </Grid>
                </Grid>


            </Grid>

        </Grid>

    )
}
