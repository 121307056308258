
import React, { useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';


import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';

import { FacturaPDF } from './FacturaPDF';
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";



export const PedidosUsu = ({ pedidos }) => {

    const [mostrarInfo, setMostrarInfo] = useState(false);
    const [factura, setFactura] = useState(false);

    const toggleMostrarInfo = (index) => {
        setMostrarInfo(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    function formatDate(dateString) {
        // Crear un objeto Date a partir del string de fecha
        const date = new Date(dateString);

        // Extraer las partes necesarias
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        // Formatear la fecha en la forma deseada
        return `${day}/${month}/${year} - ${hours}:${minutes}`;
    }

    const descargarFactura = (index) => {
        // console.log(index)
        setFactura(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    }

    const theme = createTheme({
        palette: {
            cyan: {
                main: '#47B8C2',
                light: '#47B8C2',
                dark: '#47B8C2',
                contrastText: '#EEEEEE',
            },
        },
    });

    return (
        <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems=""
            xs={12}
            className='load'
            style={{ padding: '1em', borderBottomRightRadius: '5px', borderTopRightRadius: '5px' }}
        >
            <ThemeProvider theme={theme}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                >
                    <h2>Mis pedidos</h2>
                </Grid>

                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={12} sm={12} md={12} xl={12}
                    gap={2}
                >
                    {pedidos.length > 0 ?  pedidos.map((datosPedido, index) => (
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            xs={12}
                            gap={4}
                            className='aprt-usuario fondo-blanco bordes-redondos sombra-corta'

                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                xs={12} sm={12} md={9} xl={9.5}
                                gap={2}
                                onClick={() => toggleMostrarInfo(index)}
                                style={{ padding: '1em', textAlign: 'left' }}
                            >
                                <strong>Nº Pedido: {datosPedido.idPedido}</strong>
                                <small>{datosPedido.estado}</small>
                                <small>{formatDate(datosPedido.fecha)}</small>
                                <small>Envio: {datosPedido.nombre}</small>
                                <strong>Total: {datosPedido.precioTotal}€</strong>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                xs={12} sm={12} md={2.5} xl={2}
                            >
                                <Button variant="contained" color='cyan' onClick={() => descargarFactura(index)}>Factura &nbsp; <DescriptionIcon fontSize="small" /></Button>
                                <Dialog
                                    open={factura[index]}
                                    onClose={() => descargarFactura(index)}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    maxWidth={'xl'}
                                    fullWidth={true}
                                >
                                    <DialogActions style={{ backgroundColor: '#323639', minHeight: '4vh', maxHeight: '4vh' }}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            xs={12}
                                        >
                                            <strong style={{ color: 'white' }}>Nº Pedido: {datosPedido.idPedido}</strong>
                                            <Button variant="contained" color='cyan' onClick={() => descargarFactura(index)}>Cerrar&nbsp; <CloseIcon fontSize="small" /></Button>
                                        </Grid>
                                    </DialogActions>

                                    <FacturaPDF pedido={datosPedido}></FacturaPDF>
                                </Dialog>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                xs={12}
                            >

                                <Collapse in={mostrarInfo[index]} style={{ minWidth: '100%' }}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        xs={12}
                                        gap={2}
                                    // className='fondo-gris'
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            xs={11.5}
                                            className='separador-2'
                                        >
                                        </Grid>
                                        {datosPedido.productosPedido.map((info, index) => (
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                xs={11.5}
                                                key={index}
                                                marginBottom={1}
                                            >
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    xs={11} sm={4} md={4} xl={6}
                                                    style={{ textAlign: 'left', marginTop: '-1.5em' }}
                                                >
                                                    {info.nombre}
                                                </Grid>

                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    xs={11} sm={4} md={4} xl={2}
                                                >
                                                    Cantidad: {info.cantidad}
                                                </Grid>

                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    xs={11} sm={2} md={2} xl={2}
                                                >
                                                    {(info.precio * info.cantidad).toFixed(2)} €
                                                </Grid>
                                                {index < (datosPedido.productosPedido.length - 1) ? <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    xs={12}
                                                    className='separador'
                                                >
                                                </Grid>
                                                    : <Grid
                                                        container
                                                        direction="column"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        xs={12}
                                                        style={{ minHeight: '0vh', margin: '0.5em' }}
                                                    >
                                                    </Grid>}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Collapse>
                            </Grid>
                        </Grid>
                    ) ) : " No tienes pedidos todavía "}
                </Grid>
            </ThemeProvider>
        </Grid >
    )
}
