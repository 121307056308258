import React, { useState } from 'react'

import Pagination from '@mui/material/Pagination';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import Button from '@mui/material/Button';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';

import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { CardProduct } from './CardProduct';
import { AtrasBtn } from '../componenets/botonesNav/AtrasBtn';
import { InicioBtn } from '../componenets/botonesNav/InicioBtn';


export const ListaProduct = ({ productos, itemsPerPage, titulo }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [mostrarFiltros, serMostrarFiltros] = useState(false);

    const [value, setValue] = React.useState(30);
    // const [productos, setProductos] = useState([]);
    // const [titulo, setTitulo] = useState(titulo);
    const [filtro, setFiltro] = useState(false);


    // Calcula el índice inicial y final de los elementos a mostrar en la página actual
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = productos.slice(indexOfFirstItem, indexOfLastItem);

    // Cambia la página
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = createTheme({
        palette: {
            ochre: {
                main: '#47B8C2',
                light: '#47B8C2',
                dark: '#47B8C2',
                contrastText: '#EEEEEE',
            },
        },
    });



    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            gap={2}
            className='load'
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                xs={12}
            // gap={2}
            >
                {(productos.length != 0 ? <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    textAlign={'left'}
                    xs={11} sm={2.5} md={1.5} xl={1.5}
                    gap={2}
                >
                    <h2>Filtros: </h2>
                    <FormControl variant="standard" style={{ minWidth: '100%' }} color='cyan'>
                        <InputLabel id="demo-simple-select-standard-label">Ordenar por...</InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={10}
                            onChange={handleChange}
                            label="Ordenar por..."
                        >
                            <MenuItem value={10}>Defecto</MenuItem>
                            <MenuItem value={20}>De A a Z</MenuItem>
                            <MenuItem value={30}>De Z a A</MenuItem>
                            <MenuItem value={30}>Precio acendente</MenuItem>
                            <MenuItem value={30}>Precio descendente</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                    : '')}



                {(productos.length === 0 ? <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    style={{ textAlign: 'center' }}
                >
                    <h2>No se ha encontrado ningún resultado​</h2>
                </Grid>
                    :
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={11} sm={9} md={10} xl={10}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            xs={12} sm={12} md={11} xl={12}
                        >
                            <AtrasBtn></AtrasBtn>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                xs={4} sm={4} md={6} xl={8}
                                style={{ textAlign: 'center' }}
                            >
                                <h2>Resultado de la busqueda: {titulo}​</h2>
                            </Grid>
                            <InicioBtn></InicioBtn>
                        </Grid>

                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                            gap={1}
                            style={{ marginBottom: '2em' }}
                        >
                            {productos.length > 0 ? productos.map((info, index) => (
                                <CardProduct info={info} index={index}></CardProduct>
                            )) : <h3>No hay prodcutos :(</h3>}
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                        >
                            {/* Renderiza el componente de paginación de Material-UI */}
                            <ThemeProvider theme={theme}>
                                <Pagination
                                    count={Math.ceil(productos.length / itemsPerPage)}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color="ochre"
                                />
                            </ThemeProvider>
                        </Grid>
                    </Grid>)}

            </Grid>
        </Grid>
    )
}
