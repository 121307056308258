import React, { useEffect, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import Grid from '@mui/material/Grid';

import { Header } from '../componenets/Header';
import { CabeceraTitulo } from '../componenets/CabeceraTitulo';
import { CardProduct } from '../componenets/CardProduct';
import { ListaProduct } from '../componenets/ListaProduct';
import { ListLoad } from '../componenets/loads/ListLoad';
import { AtrasBtn } from '../componenets/botonesNav/AtrasBtn';
import { InicioBtn } from '../componenets/botonesNav/InicioBtn';
import { buscarProductos } from '../services/apis';


export const Productos = () => {
    const param = useParams();
    const Navigate = useNavigate();


    const [productos, setProductos] = useState([{}]);
    const [page, setPage] = React.useState(1);
    const [cargar, setCargar] = useState(false);


    const handleChange = (event, value) => {
        setPage(value);
    };



    const cargarProductos = async () => {
        try {
            const response = await buscarProductos(param.busqueda)
            console.log(response);
            setProductos(response.data);
            setCargar(true);
        }
        catch (error) {

        }
      
    }


    useEffect(() => {
        cargarProductos();
    }, [])

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            className='load'
        >
            <Header></Header>
            <CabeceraTitulo></CabeceraTitulo>

            {cargar === true && (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12} sm={11} md={11}
                    style={{ gap: '1em' }}
                >
                    <ListaProduct productos={productos} itemsPerPage={100} titulo={param.busqueda}></ListaProduct>

                </Grid>
            )}

            {cargar === false && (
                <ListLoad></ListLoad>
            )}
        </Grid>
    )
}
