import React, { useEffect, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { Header } from '../componenets/Header';
import { CabeceraTitulo } from '../componenets/CabeceraTitulo';

import noImg from '../media/no-img.png';
import { AtrasBtn } from '../componenets/botonesNav/AtrasBtn';
import { InicioBtn } from '../componenets/botonesNav/InicioBtn';
import { useMyContext } from '../MyProvider '; //Variable global del idioma
import { getArticulo, postCesta } from '../services/apis';


export const Articulo = () => {

    const param = useParams();
    const Navigate = useNavigate();

    const { miVariableGlobal, setMiVariableGlobal } = useMyContext();

    const [cantidad, setCantidad] = useState(1);
    const [articulo, setArticulo] = useState([{}]);
    const [loaded, setLoaded] = useState(false);
    const [artAnadir, setArtAnadir] = useState({
        idProducto: null,
        nombre: null,
        cantidad: null
    });
    const handleOnChange = (event) => {
        const { name, value } = event.target;
        console.log(event.target.value);

        if (event.target.value <= 0) {
            setCantidad(1);
        } else {
            setCantidad(event.target.value);
            setArtAnadir({ ...artAnadir, cantidad: event.target.value });

            setArtAnadir(prevState => [
                ...prevState,
                { nombre: event.target.value }
            ]);
        }
    };

    const getProductos = async () => {
        try {
            const response = await getArticulo(param.idProducto);
            console.log("aa", response.data)
            setArticulo(response.data)
            setArtAnadir(response.data)
            setLoaded(true);
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    }

    const añadirProducto = async (artAnadir) => {
        // console.log(artAnadir)
        artAnadir.cantidad = cantidad;
        console.log("wok", artAnadir)
        try {
            const response = await postCesta(artAnadir);
            console.log(response.data[0]);
            Swal.fire({
                title: artAnadir.cantidad + ' ' + artAnadir.nombre + ' añadido a la cesta',
                text: 'Revisa tu cesta para mas informacion',
                icon: 'success',
                confirmButtonColor: '#47B8C2',
                timer: 1500
            })
            let temp = miVariableGlobal + parseInt(artAnadir.cantidad);
            setMiVariableGlobal(temp);
        } catch (error) {

        }

    }

    const getUltimoStock = (fecha) => {
        // Convertir la fecha proporcionada a un objeto Date
        const fechaProporcionada = new Date(fecha);

        // Obtener la fecha y hora actuales
        const fechaActual = new Date();

        // Calcular la diferencia en milisegundos
        const diferencia = fechaActual - fechaProporcionada;

        // Calcular la diferencia en días, horas, minutos y segundos
        const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
        const horas = Math.floor((diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
        const segundos = Math.floor((diferencia % (1000 * 60)) / 1000);

        if (dias >= 100) {
            return (
                <a style={{ color: 'gray' }}> +24h</a>
            );
        } else {
            return (
                <a style={{ color: 'gray' }}> {horas}h, {minutos}m, {segundos}s</a>
            );
        }
    }

    const sumarCantidad = () => {
        setCantidad(cantidad + 1);

    }

    const restarCantidad = () => {
        let temp = cantidad;

        temp = temp - 1;

        if (temp <= 0) {
            setCantidad(1);
        } else {
            setCantidad(temp);

        }
    }

    useEffect(() => {
        // console.log(param.idProducto);
        getProductos();
    }, [])



    return (

        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            className='load'
        >
            <Header></Header>
            <CabeceraTitulo></CabeceraTitulo>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                xs={11} sm={10} md={10} xl={6}
                style={{ textAlign: 'center' }}
            >
                <AtrasBtn></AtrasBtn>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={2} sm={5} md={5} xl={6}
                >
                    <h2>{articulo.nombre}</h2>
                </Grid>

                <InicioBtn></InicioBtn>

            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                xs={11} sm={10} md={10} xl={6}
                className=' fondo-blanco sombra-larga'
                style={{ marginBottom: '14em', paddingBottom: '1em' }}
            >

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={11} sm={10} md={6}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={11} sm={10} md={12}
                    >
                        <img src={noImg} alt="" style={{ maxWidth: '80%', marginTop: '1em' }} />
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    xs={11} sm={10} md={5}
                    style={{ gap: '1em', marginTop: '1em' }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={12}
                        gap={1}
                    >
                        <h2><strong>{(articulo.precio * 1.21).toFixed(2)} €</strong></h2>
                        <small style={{ color: 'gray' }}>(P.V.P.)</small>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        xs={12}
                        gap={2}
                    >
                        <a>{articulo.descripcion}</a>
                        <a>{articulo.descripcionSecundaria}</a>
                        <small><a>Cod. Referencia: {articulo.codReferencia}</a></small>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        xs={12}
                        gap={2}
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            xs={12}
                            gap={1}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                xs={12}
                            >
                                <Tooltip title="Quitar 1" className=' blur'>
                                    <IconButton>
                                        <RemoveIcon color="gray" onClick={() => restarCantidad()} />
                                    </IconButton>
                                </Tooltip>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={3.5} sm={3} md={5} xl={3}
                                >
                                    <TextField id="outlined-basic" label="Cantidad" variant="outlined" type='number' value={cantidad} size="small" onChange={handleOnChange} color="cyan" />
                                </Grid>
                                <Tooltip title="Añadir 1" className=' blur'>
                                    <IconButton>
                                        <AddIcon color="gray" onClick={() => sumarCantidad()} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>


                            <small style={{ color: 'gray' }}>Stock: {articulo.stock}</small>
                            <small style={{ color: 'gray' }}>(Actualizado hace:{getUltimoStock(articulo.ultimoStock)})</small>

                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                        >
                            <Button variant="contained" className='fondo-azul' onClick={() => añadirProducto(artAnadir)} style={{ minWidth: '100%' }}>Añadir a la cesta &nbsp; <ShoppingCartIcon fontSize="small" /></Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
