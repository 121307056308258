import React, { useEffect, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const AtrasBtn = () => {
    const Navigate = useNavigate();


    const goBack = () => {
        window.history.back();
    }

    return (
        <div>
            <Button variant="outlined" color="cyan" onClick={() => goBack()}> <ArrowBackIosIcon fontSize="small" /> &nbsp;Atras</Button>
        </div>
    )
}
