import React from 'react'
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

export const CardLoad = () => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            xs={12}
            gap={2}
            minHeight={600}
        >
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />
            <Skeleton variant="rounded" width={315} height={160} />

        </Grid>
    )
}
