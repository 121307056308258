import axios from "axios";
import { getCookie } from "../utils/cookies";


const baseUrl = 'https://www.57grupo.com/tienda/WS/'

///datos usuarios

export const getUserData = async () => {
    try {
        const response = await axios.get(baseUrl + 'datosUsuario.php', {
            params: { token: getCookie('PHPSESSID') },

        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            console.log(response.data)
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

// articulos

export const getArticulo = async (idProducto) => {
    try {
        const response = await axios.get(baseUrl + 'articulo.php', {
            params: {
                token: getCookie('PHPSESSID'),
                idProducto: idProducto
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            console.log("data", response.data)
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

// Categorias

export const getCategorias = async (code) => {
    try {
        const response = await axios.get(baseUrl + 'categorias.php', {
            params: {
                token: getCookie('PHPSESSID'),
                code: code
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

// Cesta

export const getCesta = async (idCliente) => {
    try {
        const response = await axios.get(baseUrl + 'cesta.php', {
            params: {
                token: getCookie('PHPSESSID'),
                idCliente: idCliente
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const postCesta = async (data) => {
    console.log("postCestas")
    try {
        const response = await axios.post(baseUrl + 'cesta.php', {
            token: getCookie('PHPSESSID'),
            data
        });
        console.log("aaaa", response);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};
export const updateCesta = async (data) => {
    try {
        const response = await axios.put(baseUrl + 'cesta.php', {
            token: getCookie('PHPSESSID'),
            data

        });
        console.log(response.data);
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario

            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};
export const deleteCesta = async (data) => {
    console.log("yyea", data)
    try {
        const response = await axios.delete(baseUrl + 'cesta.php', {
            params: {
                token: getCookie('PHPSESSID'),

            },
            data: data
        });

        console.log(response.data);
        if (response.data.success) {
            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};
//envios

export const getEnvios = async () => {
    try {
        const response = await axios.get(baseUrl + 'envios.php', {
            params: {
                token: getCookie('PHPSESSID')
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};


// titulo

export const getTitulosSubcat = async (idSubcategoria) => {
    try {
        const response = await axios.get(baseUrl + 'getTitulo.php', {
            params: {
                token: getCookie('PHPSESSID'),
                idSubcategoria: idSubcategoria
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getTitulos = async (idCategoria) => {
    try {
        const response = await axios.get(baseUrl + 'getTitulo.php', {
            params: {
                token: getCookie('PHPSESSID'),
                idCategoria: idCategoria,
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};


//pedidosUsuario
export const getPedidosUsuario = async () => {
    try {
        const response = await axios.get(baseUrl + 'pedidosUsuario.php', {
            params: {
                token: getCookie('PHPSESSID'),
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error :', error);
        return null;
    }
};

export const postPedidosUsuario = async (data) => {
    console.log("data pedido", data)
    try {
        const response = await axios.post(baseUrl + 'pedidosUsuario.php', {
            token: getCookie('PHPSESSID'),
            data
        });
        console.log(response.data);
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario

            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};


//productos
export const getProductos = async (idSubcategoria) => {
    try {
        const response = await axios.get(baseUrl + 'productos.php', {
            params: {
                token: getCookie('PHPSESSID'),
                idSubcategoria: idSubcategoria
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

//subcategorias

export const getSubaCategoria = async (idSubcategoria) => {
    try {
        const response = await axios.get(baseUrl + 'subcategoria.php', {
            params: {
                token: getCookie('PHPSESSID'),
                idCategoria: idSubcategoria
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};


//buscar

export const buscarProductos = async (busqueda) => {
    try {
        const response = await axios.get(baseUrl + 'productos.php' , {
            params: {
                token: getCookie('PHPSESSID'),
                busqueda: busqueda
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};
