import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';

import Grid from '@mui/material/Grid';

import { Buscar } from './Buscar';
import { useMyContext } from '../MyProvider '; //Variable global del idioma


import Logo from '../media/HOME-01.png'
import { getCookie } from '../utils/cookies';
import { getCesta } from '../services/apis';

export const Header = () => {

    
	const token = getCookie('PHPSESSID');
	const [option, setOption] = useState();
    const Navigate = useNavigate();
    const [idCliente, setIdCliente] = useState("0");
    const { miVariableGlobal, setMiVariableGlobal } = useMyContext();


    const cargarCesta = async() => {
        try{
            const response = await getCesta(idCliente); 
            let temp = response.data;

                setMiVariableGlobal(cantidadProductos(temp));
        } catch(error){
            console.log("Error cargar cesta", error)
        }

    }

    const cantidadProductos = (productos) => {
        let cantidad = 0;

        for (let i = 0; i < productos.length; i++) {
            cantidad = cantidad + productos[i].cantidad;
        }

        return cantidad;
    }

    useEffect(() => {
        cargarCesta();
    }, [])

    const cerrarSesion = async () => {


		localStorage.removeItem('token');
		localStorage.removeItem('username');
		localStorage.removeItem('code');
		localStorage.removeItem('mail');
		localStorage.removeItem('DesRepresentante');
        document.cookie = "PHPSESSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";


		Navigate("/login");

		try {
			const response = await axios.post('https://compras.clasol.com/WS/Logout.php', {
				'token': token,
			}, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			console.log('Respuesta del servidor:', response.data);


		} catch (error) {
			console.error('Error al invalidar el token', error);
		}
	};

	const rolVerf = async () => {
		try {
			const response = await axios.post('https://compras.clasol.com/WS/MenuOptions.php', {
				'token': token,
			}, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			setOption(response.data.optionmenu);
		} catch (error) {
			console.error('Error al invalidar el token', error);
		}
	};

	useEffect(() => {
		rolVerf();
	}, [])
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            className='fondo-azul load'
            style={{ padding: '1em' }}
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                xs={12}
                style={{ gap: '0.5em' }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={12} md={3} xl={4}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={10} sm={10} md={11} xl={5}
                        className='no-select pointer blur'
                        onClick={() => Navigate("/")}
                    >
                        <img src={Logo} alt="" style={{ maxWidth: '100%' }} />
                    </Grid>
                </Grid>

                <Buscar></Buscar>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ gap: '1em' }}
                    xs={12} sm={12} md={3} xl={4}
                >

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={5} sm={2.5} md={5} xl={2}
                        className='boton-header fondo-blanco'
                        onClick={() => Navigate("/")}
                    >
                        Categorias
                    </Grid>
 
                    {/*<Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={3} sm={2} md={4.5} xl={2}
                        className='boton-header fondo-blanco'
                        onClick={() => Navigate("/productos")}
                    >
                        Productos
                    </Grid> */}
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={5} sm={2.5} md={5} xl={2}
                        className='boton-header fondo-blanco'
                        onClick={() => Navigate("/cesta")}
                    >
                        Cesta: {miVariableGlobal}
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={5} sm={2.5} md={5} xl={2}
                        className='boton-header fondo-blanco'
                        onClick={() => Navigate("/usuario")}
                    >
                        Usuario
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={5} sm={2.5} md={5} xl={2}
                        className='boton-header fondo-blanco'
                        onClick={() => cerrarSesion()}
                    >
                        Salir
                    </Grid>
                </Grid>
            </Grid>

        </Grid>

    )
}
