import React from 'react'
import Grid from '@mui/material/Grid';


export const CabeceraTitulo = () => {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            style={{ textAlign: 'center' }}
            className='fondo-blanco sombra-larga no-select'
        >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={10}
                style={{ textAlign: 'center' }}
            >
                <h1>57Grupo | Expertos en suministros industriales​</h1>
            </Grid>
        </Grid>
    )
}
