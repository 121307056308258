import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import { Header } from '../componenets/Header';
import { AtrasBtn } from '../componenets/botonesNav/AtrasBtn';
import { InicioBtn } from '../componenets/botonesNav/InicioBtn';
import { useMyContext } from '../MyProvider '; //Variable global del idioma
import { TramitarPedido } from '../componenets/TramitarPedido';
import { deleteCesta, getCesta, getEnvios, updateCesta } from '../services/apis';


export const Cesta = () => {

    const Navigate = useNavigate();

    // --------------------------------------------------------PRODUCTOS
    const [cantidad, setCantidad] = useState(1);
    const [articulos, setArticulos] = useState([{}]);

    // --------------------------------------------------------ENVIOS
    const [envios, setEnvios] = useState([{}]);
    const [selectEnvio, setSelectEnvio] = useState(0);
    const [precioEnvio, setPrecioEnvio] = useState(0.00);

    // --------------------------------------------------------COSTE
    const [totalCesta, setTotalCesta] = useState(0);
    const [IVA, setIVA] = useState(0);
    const [totalCestaIVA, setTotalCestaIVA] = useState(0);
    const [total, setTotal] = useState(0);

    // --------------------------------------------------------OTROS
    const [loaded, setLoaded] = useState(false);
    const [idCliente, setIdCliente] = useState("0");
    const [timer, setTimer] = useState(null);
    const { miVariableGlobal, setMiVariableGlobal } = useMyContext();
    const [tramitarPedido, setTramitarPedido] = useState(false);
    const [pedidoFinal, setPedidoFinal] = useState({});


    const handleOnChange = (event) => {
        // console.log(parseInt(event.target.value))
        setSelectEnvio(parseInt(event.target.value));
        let tempSelectEnvio = parseInt(event.target.value)
        getTotal(articulos, envios, tempSelectEnvio)

    };

    const cargarEnvios = async () => {
        try {
            const response = await getEnvios();
            console.log("getEnvios", response.data)
            setEnvios(response.data)
            cargarCesta(response.data);
        } catch (error) {
            console.error("Error getEnvios:", error);
        }
    }

    const cargarCesta = async (envios) => {
        try {
            const response = await getCesta(idCliente);
            console.log("cargarCesta", response.data);
            setArticulos(response.data);
            getTotal(response.data, envios, selectEnvio);
        } catch (error) {
            console.error("Error cargarCesta:", error);
        }
    }

    const borrarCesta = async (producto) => {
        console.log("asopla");
        try {
            const response = await deleteCesta(producto);
            console.log("borrarCesta", response);
            if (response.success == true) {
                let temp = [...articulos];
                temp = temp.filter((tmp) => tmp.idProducto != producto.idProducto);
                setArticulos(temp);
                getTotal(temp, envios, selectEnvio);
            }
        } catch (error) {
            console.error("Error al borrar de la cesta:", error);
        }
    }

    const getTotal = (arrArticulos, envios, selectEnvio) => {
        // console.log(selectEnvio);
        let pesoTotal = 0.00;
        let cantidad = 0;

        for (let i = 0; i < arrArticulos.length; i++) {
            cantidad = cantidad + arrArticulos[i].cantidad;
        }

        for (let i = 0; i < arrArticulos.length; i++) {
            pesoTotal = pesoTotal + (arrArticulos[i].peso * arrArticulos[i].cantidad);

        }
        // console.log(pesoTotal);
        let tempPrecioEnvio = (pesoTotal * envios[selectEnvio].precioKilo);
        setPrecioEnvio((pesoTotal * envios[selectEnvio].precioKilo));


        // console.log(envio)
        let total = 0.00;
        let porcetIVA = 1.21;
        for (let i = 0; i < arrArticulos.length; i++) {

            total += arrArticulos[i].precio * arrArticulos[i].cantidad;
        }


        setTotalCesta(parseFloat(total.toFixed(2)));

        // console.log(tempPrecioEnvio)
        let totalConIVA = (total * porcetIVA);

        let tempTotalCestaIVA = parseFloat(totalConIVA.toFixed(2));
        setTotalCestaIVA(parseFloat(totalConIVA.toFixed(2)));

        let tempTotal = tempTotalCestaIVA + tempPrecioEnvio;

        setTotal(parseFloat(tempTotal.toFixed(2)));

        getIVA(tempTotalCestaIVA, total);

        setMiVariableGlobal(cantidad)
    }

    const getIVA = (totalCestaIVA, total) => {
        let tempIVA = parseFloat((totalCestaIVA - total).toFixed(2));
        setIVA(tempIVA);
        setLoaded(true);
    }

    const cambiarCantidad = (producto, event) => {
        let tempCantidad = event.target.value;

        if (tempCantidad <= 0) {
            tempCantidad = 1;
        } else {
            let temp = [...articulos];
            const index = temp.findIndex((tmp) => tmp.idProducto === producto.idProducto);

            if (index !== -1) {
                const nuevoProducto = { ...producto, cantidad: tempCantidad };
                temp[index] = nuevoProducto;
            }
            setArticulos(temp);
            getTotal(temp, envios, selectEnvio);

            // Cancelar el temporizador existente si hay alguno
            if (timer) {
                clearTimeout(timer);
            }

            // Establecer un nuevo temporizador para ejecutar la función después de 2000ms (2 segundos)
            setTimer(setTimeout(() => {
                producto.cantidad = tempCantidad;
                cambiarCantidadBackEnd(producto);
            }, 1000));
        }
    };

    const sumarCantidad = (producto) => {
        let tempCantidad = producto.cantidad + 1;

        if (tempCantidad <= 0) {
            tempCantidad = 1;
        } else {
            let temp = [...articulos];
            const index = temp.findIndex((tmp) => tmp.idProducto === producto.idProducto);

            if (index !== -1) {
                const nuevoProducto = { ...producto, cantidad: tempCantidad };
                temp[index] = nuevoProducto;
            }
            setArticulos(temp);
            getTotal(temp, envios, selectEnvio);

            // Cancelar el temporizador existente si hay alguno
            if (timer) {
                clearTimeout(timer);
            }

            // Establecer un nuevo temporizador para ejecutar la función después de 2000ms (2 segundos)
            setTimer(setTimeout(() => {
                producto.cantidad = tempCantidad;
                cambiarCantidadBackEnd(producto);
            }, 1000));
        }
    };

    const restarCantidad = (producto) => {
        let tempCantidad = producto.cantidad - 1;

        if (tempCantidad <= 0) {
            tempCantidad = 1;
        } else {
            let temp = [...articulos];
            const index = temp.findIndex((tmp) => tmp.idProducto === producto.idProducto);

            if (index !== -1) {
                const nuevoProducto = { ...producto, cantidad: tempCantidad };
                temp[index] = nuevoProducto;
            }
            setArticulos(temp);
            getTotal(temp, envios, selectEnvio);

            // Cancelar el temporizador existente si hay alguno
            if (timer) {
                clearTimeout(timer);
            }

            // Establecer un nuevo temporizador para ejecutar la función después de 2000ms (2 segundos)
            setTimer(setTimeout(() => {
                producto.cantidad = tempCantidad;
                cambiarCantidadBackEnd(producto);
            }, 1500));
        }
    };

    const cambiarCantidadBackEnd = async (producto) => {
        console.log(producto);
        try {
            const response = await updateCesta(producto);
            console.log("upadteCesta", response.data);
            // setArticulos(response.data);
            // getTotal(response.data, envios, selectEnvio);
            // costeEnvio(response.data);
        } catch (error) {

        }
    }
    const finalizarPedido = () => {
        const pedido = {
            articulos: articulos,
            envio: envios[selectEnvio],
            costes: {
                costeSinIVA: totalCesta,
                IVA: IVA,
                envio: precioEnvio,
                costeTotal: total
            },
            idCliente: 0
        };
        setPedidoFinal(pedido);
        setTramitarPedido(true);
        // console.log(pedido);
    };


    useEffect(() => {
        cargarEnvios();
    }, [])

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            className='load'
            style={{ marginBottom: '8em' }}
        >
            <Header></Header>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                xs={11} sm={11} md={10} xl={10}
                style={{ textAlign: 'center', marginTop: '1em', marginBottom: '-2em' }}
            >
                <AtrasBtn></AtrasBtn>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={4}
                    style={{ textAlign: 'center' }}
                >
                    <h1>Cesta​</h1>
                </Grid>
                <InicioBtn></InicioBtn>
            </Grid>
            {loaded === false && (
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    style={{ minHeight: '30vh' }}
                >
                    <CircularProgress color="cyan" />
                </Grid>
            )}
            {loaded === true && (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    xs={11} sm={11} md={10} xl={10}
                    className='fondo-blanco sombra-larga cesta-card'
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        xs={12} sm={12} md={12} xl={6}
                        style={{ textAlign: 'flex-start' }}
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            xs={6}
                            style={{ textAlign: 'flex-start' }}
                        >
                            <h2>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={12}
                                >
                                    <InventoryIcon color='cyan' /> &nbsp;Articulos: {miVariableGlobal}
                                </Grid>
                            </h2>
                        </Grid>

                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            xs={12} sm={12} md={12} xl={11}
                            className='lista-cesta'
                        >
                            <div>
                                {articulos.length === 0 ? (
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        xs={12}
                                    >
                                        <p style={{ color: 'gray' }}>No hay articulos en la cesta</p>
                                    </Grid>
                                ) : ''}
                            </div>
                            {/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ARTICULOS */}
                            {articulos.length > 0 ? articulos.map((info, index) => (
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={12}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        xs={12}
                                        gap={1}
                                        key={index}
                                        style={{ textAlign: 'flex-start', padding: '0em 1em' }}
                                        className='aprt-usuario bordes-redondos'
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            xs={12} sm={6} md={6} xl={7}
                                            gap={1}
                                            onClick={() => Navigate("/articulo/" + info.idProducto)}
                                            textAlign={'left'}
                                        >
                                            <a>{info.nombre}</a>
                                            <strong>{info.precio} €</strong>
                                            <small style={{ color: 'gray' }}>Cantidad: {info.cantidad}</small>

                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            xs={8} sm={4} md={4} xl={3}
                                            className=' blur'
                                        >
                                            <Tooltip title="Quitar 1" className=' blur'>
                                                <IconButton>
                                                    <RemoveIcon color="gray" onClick={() => restarCantidad(info)} />
                                                </IconButton>
                                            </Tooltip>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                xs={7} sm={4} md={4} xl={6}
                                            >
                                                <TextField id="outlined-basic" label="Cantidad" variant="outlined" type='number' value={info.cantidad} size="small" onChange={(event) => cambiarCantidad(info, event)} color="cyan" />
                                            </Grid>
                                            <Tooltip title="Añadir 1" className=' blur'>
                                                <IconButton>
                                                    <AddIcon color="gray" onClick={() => sumarCantidad(info)} />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            xs={2} sm={1}
                                            style={{ minHeight: '10vh' }}
                                        >
                                            <Tooltip title="Eliminar" className=' blur'>
                                                <IconButton>
                                                    <DeleteForeverIcon color="gray" onClick={() => borrarCesta(info)} />
                                                </IconButton>
                                            </Tooltip>

                                        </Grid>

                                    </Grid>
                                    {index < (articulos.length - 1) ? <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        xs={12}
                                        className='separador'
                                    >
                                    </Grid>
                                        : ''}
                                </Grid>
                            )) : ""}

                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        xs={12} sm={12} md={12} xl={6}
                        style={{ textAlign: 'flex-start', marginBottom: '1em' }}
                    >


                        {loaded === true && (

                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                xs={12}
                            >

                                {/* -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------COSTE */}

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    xs={12}
                                >
                                    <h2>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            xs={12}
                                        >
                                            <AccountBalanceWalletIcon color='cyan' />&nbsp;Costes:
                                        </Grid>
                                    </h2>
                                    <p>Coste sin I.V.A.: {totalCesta}€</p>
                                    <p>I.V.A. <small style={{ color: 'gray' }}>(21%)</small>: {IVA}€</p>
                                    <p>Coste Total: {totalCestaIVA}€</p>

                                </Grid>


                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    xs={12}
                                >
                                    <p>Envio: {precioEnvio}€</p>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        xs={12}
                                    >
                                        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ENVIOS */}
                                        <FormControl style={{ minWidth: '100%', textAlign: 'left', }}>
                                            <h2>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    xs={12}
                                                >
                                                    <LocalShippingIcon color='cyan' />
                                                    &nbsp;
                                                    Envios:
                                                </Grid>
                                            </h2>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                id="demo-simple-select-autowidth"
                                                value={selectEnvio}
                                                defaultValue={selectEnvio}
                                                onChange={(event) => {
                                                    handleOnChange(event);
                                                }}
                                                // autoWidth
                                                label="Envios"
                                                color="cyan"
                                            >
                                                {/* <MenuItem value={0}>Selecciona una opcion de envio...</MenuItem> */}
                                                {envios.map((envio, index) => (
                                                    // <MenuItem value={envio.id}>{envio.nombre + ' Coste: ' + envio.precioKilo + '€/Kg | Descripcion: ' + envio.descripcion}</MenuItem>
                                                    <FormControlLabel value={envio.id} control={<Radio color="cyan" />} label={envio.nombre + ' Coste: ' + envio.precioKilo + '€/Kg | Descripcion: ' + envio.descripcion} />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <h2>Total: {total}€</h2>
                                </Grid>

                            </Grid>
                        )}

                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-end"
                            alignItems="center"
                            xs={12}
                        >
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-end"
                                alignItems="center"
                                xs={12}
                            // className='boton-principal fondo-azul'
                            // onClick={() => finalizarPedido()}
                            >
                                <Button
                                    disabled={articulos.length === 0}
                                    variant="contained"
                                    color="cyan"
                                    className={articulos.length === 0 ? 'fuente' : 'fondo-azul fuente'}
                                    style={{ minWidth: '100%', padding: '1em' }}
                                    onClick={() => finalizarPedido()}
                                >
                                    Tramitar Pedido
                                </Button>
                            </Grid>
                            {(tramitarPedido == true ? <TramitarPedido tramitarPedido={tramitarPedido} setTramitarPedido={setTramitarPedido} pedidoFinal={pedidoFinal}></TramitarPedido> : '')}
                        </Grid>
                    </Grid>
                </Grid>)}
        </Grid>
    )
}
