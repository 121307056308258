import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import emailjs from '@emailjs/browser';


import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';

import CloseIcon from '@mui/icons-material/Close';
import { postPedidosUsuario } from '../services/apis';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const TramitarPedido = ({ tramitarPedido, setTramitarPedido, pedidoFinal }) => {
    const Navigate = useNavigate();
    const form = useRef();

    const [finalizado, setFinalizado] = useState(false);
    const [timer, setTimer] = useState(null);
    const [temp, setTemp] = useState(tramitarPedido);
    const [usuario, setUsuario] = useState({
        nombre: 'Ivan',
        mail: 'tienda@57grupo.com'
    })


    const handleClose = () => {
        setTemp(false)

        if (timer) {
            clearTimeout(timer);
        }
        setTimer(setTimeout(() => {
            setTramitarPedido(false);
        }, 1500));

    };

    const finalizarPedido = async () => {
        // console.log(pedidoFinal);
        try {
            const response = await postPedidosUsuario(pedidoFinal)
            console.log(response);
            // emailjs.send('service_r1yrs3x', 'template_fgl2bnj', {
            //     from_name: "Tienda - 57Grupo",
            //     to_name: usuario.nombre,
            //     message: "Pedido Nº" + response.data.pedido + " confirmado. Consulta tu apartado de Usuario para ver el estado de tus pedidos. ",
            //     mail_to: usuario.mail,
            // }, 'qY4NEgGH2lhq-Enh8')
            //     .then(
            //         () => {
            //             console.log('SUCCESS!');
            //         },
            //         (error) => {
            //             console.log('FAILED...', error.text);
            //         }
            //     );

            
            setFinalizado(true);

        }
        catch (error) {

        }

    };

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
        >
            {((finalizado == false) ?
                <Dialog
                    open={temp}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    TransitionComponent={Transition}
                    keepMounted
                    fullScreen
                >
                    <DialogContent className='fondo-blanco finalizar-pedido' >
                        <DialogContentText id="alert-dialog-description">
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                xs={12}
                            >
                                <h2>Finalizar pedido</h2>

                                <CloseIcon className='pointer' onClick={handleClose} fontSize='large' />
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                                xs={12}
                                gap={2}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    xs={12}
                                >
                                    {pedidoFinal.articulos.map((info, index) => (
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            xs={11} sm={12} md={12}
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                xs={12}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    xs={6}
                                                >
                                                    <a>{info.nombre}</a>
                                                    <small>Cantidad: {info.cantidad}</small>
                                                </Grid>
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    xs={1}
                                                >
                                                    <strong>{info.precio}€</strong>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                xs={12}
                                                className='separador'
                                            >
                                            </Grid>
                                        </Grid>

                                    ))}
                                </Grid>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    xs={12}
                                >
                                    <h3>Coste: {pedidoFinal.costes.costeSinIVA}€</h3>
                                    <h3>I.V.A.: {pedidoFinal.costes.IVA}€</h3>
                                    <h3>Envio: {pedidoFinal.costes.envio}€</h3>
                                    <small style={{ marginTop: '-1.5em' }}>Seleccionado: {pedidoFinal.envio.nombre}</small>
                                    <h2>Coste total: {pedidoFinal.costes.costeTotal}€</h2>
                                </Grid>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    xs={12}
                                    className='boton-principal fondo-azul'
                                    onClick={() => finalizarPedido()}
                                >
                                    Finalizar Pedido
                                </Grid>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    xs={12}
                                    className='boton-secundario'
                                    onClick={handleClose}
                                >
                                    Cancelar
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                :

                <Dialog
                    open={tramitarPedido}
                    onClose={() => { handleClose(); window.location.reload(); }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"

                >
                    <DialogContent className='fondo-blanco finalizar-pedido' >
                        <DialogContentText id="alert-dialog-description">
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                xs={12}
                                gap={2}

                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    xs={12}
                                >
                                    <h1>Pedido finalizado</h1>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    xs={12}
                                    gap={2}
                                    style={{ textAlign: 'center' }}
                                >
                                    <h3>Pedido finalizado con exito!</h3>
                                    <strong>Puedes consultar su estado en
                                        <strong
                                            className='pointer'
                                            onClick={() => Navigate("/usuario")}
                                        > Usuario/Mis pedidos</strong>
                                    </strong>
                                </Grid>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    xs={12}
                                    className='boton-principal fondo-azul'
                                    onClick={() => { setTramitarPedido(false); handleClose(); window.location.reload(); }}
                                >
                                    Aceptar
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            )
            }
        </Grid >
    )
}
