import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';
import IconButton from '@mui/material/IconButton';

import { HeaderNoLog } from '../componenets/HeaderNoLog';
import Swal from 'sweetalert2';



export const Login = ({ username, setUsername, password, setPassword }) => {
    const Navigate = useNavigate();
    const wsUrl = "tienda.57grupo.com";

    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();


    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleLogin();
        }
    };
    const handleLogin = async () => {
        try {
            const response = await axios.post(`https://${wsUrl}/WS/login.php`, {
                username: username,
                password: password,
            });
            if (response.data.success) {
                // Guarda el token en una cookie con una fecha de expiración
                const tokenExpiryDate = new Date();
                tokenExpiryDate.setTime(tokenExpiryDate.getTime() + (12 * 60 * 60 * 1000)); // 12 horas
                document.cookie = `PHPSESSID=${response.data.token}; path=/; expires=${tokenExpiryDate.toUTCString()};`;

                // Guarda otros datos en el almacenamiento local
                localStorage.setItem('username', response.data.nombre);
                localStorage.setItem('mail', response.data.mail);
                localStorage.setItem('DesRepresentante', response.data.nombre);
                setPassword('');
                setUsername('');

                Swal.fire({
                    icon: "success",
                    title: `Bienvenido/a \n${username}`,
                    showConfirmButton: false,
                    timer: 1500
                });
                navigate('/');
            } else {
               Swal.fire({
                    icon: "error",
                    title: `Error de inicio de sesión: \n${response.data.message}`,
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        } catch (error) {
            console.error('Error de red:', error.message);
        }
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
 
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
        >
            <HeaderNoLog></HeaderNoLog>
            {/* <CabeceraTitulo></CabeceraTitulo> */}


            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={12}
                style={{ marginTop: '5em' }}
            >

                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={11} sm={8} md={6} xl={3}
                    gap={2}
                    className='login fondo-blanca sombra-corta'
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        style={{ marginBottom: '-2em' }}
                    >
                        <PersonIcon sx={{ fontSize: 80 }} style={{ marginBottom: '-0.3em' }} color='cyan' />
                        <h1>Iniciar sesión</h1>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            xs={11} sm={6} md={6} xl={8}
                            className='separador-2'
                        >
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        xs={11} sm={8} md={8} xl={8}
                        style={{ marginBottom: '-1em' }}

                    >
                        <h2>Usuario</h2>
                        <TextField id="outlined-basic" label="Usuario" variant="outlined" color='cyan' style={{ minWidth: '100%' }} onChange={(e) => setUsername(e.target.value)} onKeyPress={handleKeyPress}/>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        xs={11} sm={8} md={8} xl={8}
                    >
                        <h2>Contraseña</h2>
                        <FormControl variant="outlined" style={{ minWidth: '100%' }} onKeyPress={handleKeyPress}>
                            <InputLabel htmlFor="outlined-adornment-password" color='cyan' >Contraseña</InputLabel>
                            <OutlinedInput
                                color='cyan'
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Contraseña"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormControl >
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        xs={11} sm={8} md={8} xl={8}
                    >
                        <Button variant="contained" color='cyan' style={{ minWidth: '100%' }} onClick={() => handleLogin()}>Iniciar sesion&nbsp;<LoginIcon fontSize='small' /></Button>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={11} sm={8} md={8} xl={8}
                        style={{ marginBottom: '1em' }}
                    >
                        <small style={{ color: 'gray', textAlign: 'center', padding: '1em 0em' }}>Si desea darse de alta como cliente contactenos a: tienda@57grupo.com</small>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>

    )
}
