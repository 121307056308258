import React, { useEffect, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';

export const InicioBtn = () => {

    const Navigate = useNavigate();

    return (
        <div>
            <Button variant="outlined" color="cyan" onClick={() => Navigate("/")}> <HouseSidingIcon /> &nbsp;Inicio</Button>

        </div>
    )
}
