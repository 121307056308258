import React, { useEffect, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Collapse from '@mui/material/Collapse';


import { Header } from '../componenets/Header';
import { CabeceraTitulo } from '../componenets/CabeceraTitulo';
import { CardProduct } from '../componenets/CardProduct';
import { AtrasBtn } from '../componenets/botonesNav/AtrasBtn';
import { InicioBtn } from '../componenets/botonesNav/InicioBtn';
import { getProductos, getTitulos, getTitulosSubcat } from '../services/apis';


export const Subcategorias = () => {


    const Navigate = useNavigate();
    const param = useParams();

    const [value, setValue] = React.useState(30);
    const [productos, setProductos] = useState([]);
    const [titulo, setTitulo] = useState("");
    const [filtro, setFiltro] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const cargarTitulo = async () => {
        try {
            const response = await getTitulosSubcat(param.subcategoria)
            setTitulo(response.data[0].nombre);

        } catch (error) {
            console.log("", error);
        }
    }

    const cargarProductos = async () => {
        try {
            const response = await getProductos(param.subcategoria);
            console.log(response);
            setProductos(response.data);
        } catch (error) {
            console.log("Error al cargar productos ", error);
        }
    }


    useEffect(() => {
        cargarTitulo();
        cargarProductos();
    }, [])

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            gap={2}
            className='load'
        >

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={12}
                className='load'
            >
                <Header></Header>
                <CabeceraTitulo></CabeceraTitulo>

            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                xs={12}
            // gap={2}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    xs={11} sm={2.5} md={1.5} xl={1.5}
                    gap={2}
                >
                    <h2>Filtros: </h2>
                    <FormControl variant="standard" style={{ minWidth: '100%' }} color='cyan'>
                        <InputLabel id="demo-simple-select-standard-label">Ordenar por...</InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={10}
                            onChange={handleChange}
                            label="Ordenar por..."
                        >
                            <MenuItem value={10}>Defecto</MenuItem>
                            <MenuItem value={20}>De A a Z</MenuItem>
                            <MenuItem value={30}>De Z a A</MenuItem>
                            <MenuItem value={30}>Precio acendente</MenuItem>
                            <MenuItem value={30}>Precio descendente</MenuItem>
                        </Select>
                    </FormControl>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={12} sm={12} md={12} xl={12}
                        className='cabecera-filtros blur fondo-blanco sombra-corta bordes-redondos'
                    >
                        <h3
                            className='pointer'
                            style={{ margin: '0.5em' }}
                            onClick={() => filtro == true ? setFiltro(false) : setFiltro(true)}
                        >
                            Filtros 1
                        </h3>
                        <Collapse in={filtro}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                                xs={12} sm={12} md={12} xl={6}
                                className='fondo-blanco bordes-redondos'
                            >
                                <a><Checkbox defaultUnChecked color="cyan" />Flitro 1</a>
                                <a><Checkbox defaultUnChecked color="cyan" />Flitro 2</a>
                                <a><Checkbox defaultUnChecked color="cyan" />Flitro 3</a>
                            </Grid>
                        </Collapse>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={11} sm={9} md={10} xl={10}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        xs={12} sm={12} md={11} xl={11}
                    >
                        <AtrasBtn></AtrasBtn>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            xs={4} sm={4} md={6} xl={8}
                            style={{ textAlign: 'center' }}
                        >
                            <h2>{titulo}​</h2>
                        </Grid>
                        <InicioBtn></InicioBtn>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={11}
                        gap={1}
                        style={{ marginBottom: '25em' }}
                    >
                        {productos.length ? productos.map((info, index) => (
                            <CardProduct info={info} index={index}></CardProduct>
                        )) 
                        : <h3>No hay productos disponibles</h3>}
                    </Grid>
                </Grid>

            </Grid>
        </Grid >
    )
}
