import React, { useEffect, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Tooltip from '@mui/material/Tooltip';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import { useMyContext } from '../MyProvider '; //Variable global del idioma


import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { postCesta } from '../services/apis';


export const CardProduct = ({ info, index }) => {

    const Navigate = useNavigate();

    const { miVariableGlobal, setMiVariableGlobal } = useMyContext();

    const [timeout, setTimeout] = useState(index * 100);
    const [cantidad, setCantidad] = useState(1);
    const [artAnadir, setArtAnadir] = useState({
        idProducto: info.idProducto,
        nombre: info.nombre,
        cantidad: null
    });

    const añadirProducto = async (artAnadir) => {

        artAnadir.cantidad = cantidad;
        console.log(artAnadir)
        ///  añadir a la cesta
        try {
            const response = await postCesta(artAnadir);
            console.log("añadirProducto", response)
            if (response.success == true) {
                Swal.fire({
                    title: artAnadir.cantidad + ' ' + artAnadir.nombre + ' añadido/s a la cesta',
                    text: 'Revisa tu cesta para mas informacion',
                    icon: 'success',
                    confirmButtonColor: '#47B8C2',
                    timer: 1500
                })
                let temp = miVariableGlobal + parseInt(artAnadir.cantidad);
                setMiVariableGlobal(temp);
            }

        } catch (error) {
            console.log("Error al añadir a la Cesta")
        }

        try {

        } catch (error) {
            console.error("Error inesperado")
        }

    }

    const cambiarCantidad = (producto, event) => {
        console.log(event.target.value);

        if (event.target.value <= 0) {
            setCantidad(1);
            artAnadir.cantidad = 1;
        } else {
            setCantidad(event.target.value);
            artAnadir.cantidad = event.target.value;
        }



    };

    const sumarCantidad = (producto) => {
        setCantidad(Number(cantidad) + 1);
        producto.cantidad = Number(producto.cantidad) + 1;
    };

    const restarCantidad = (producto) => {
        let temp = cantidad;
        if (temp <= 1) {
            setCantidad(1)
            producto.cantidad = 1;
        } else {
            setCantidad(cantidad - 1)
            producto.cantidad = producto.cantidad - 1;
        }
    };

    const getTimeout = (timeout) => {

        if (timeout >= 2000) {
            setTimeout(2000);
        }

        return (timeout);
    }


    return (
        <>
            {/* <Grow
                in={true} style={{ transformOrigin: '0 0 0' }}
                {...(true ? { timeout: getTimeout(timeout) } : {})}
            > */}
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    xs={12} sm={12} md={11} xl={12}
                    className='producto-card sombra-corta fondo-blanco'
                    key={index}
                    gap={1}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        xs={12} sm={5} md={5} xl={7.5}
                        onClick={() => Navigate("/articulo/" + info.idProducto)}
                        style={{ textAlign: 'left' }}
                    >
                        <h4 className='no-margen' onClick={() => Navigate("/articulo/" + info.idProducto)}>{info.nombre}</h4>
                        {/* <small style={{ color: 'grey' }}>Descripcion: {info.descripcion}</small> */}
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={12} sm={7} md={6} xl={4}
                        gap={2}
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            xs={12} sm={3} md={2} xl={3}
                            gap={0}
                            onClick={() => Navigate("/articulo/" + info.idProducto)}
                        >
                            <strong>{(info.precio * 1.21).toFixed(2)} €</strong>
                            <small style={{ color: 'grey' }}>Stock: {info.stock}</small>

                        </Grid>


                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            xs={12} sm={8} md={8} xl={8}
                            className=' blur'
                        >
                            <Tooltip title="Quitar 1" className=' blur'>
                                <IconButton>
                                    <RemoveIcon color="gray" onClick={() => restarCantidad(info)} />
                                </IconButton>
                            </Tooltip>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                xs={6} sm={3} md={4} xl={3.5}
                            >
                                <TextField id="outlined-basic" label="Cantidad" variant="outlined" type='number' value={cantidad} size="small" onChange={(event) => cambiarCantidad(artAnadir, event)} color="cyan" />
                            </Grid>
                            <Tooltip title="Añadir 1" className=' blur'>
                                <IconButton>
                                    <AddIcon color="gray" onClick={() => sumarCantidad(info)} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={"Añadir " + cantidad + " a la cesta"}>
                                <Button
                                    variant="contained"
                                    className='fondo-azul'
                                    onClick={() => añadirProducto(artAnadir)}
                                >
                                    <ShoppingCartIcon fontSize="small" />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            {/* </Grow> */}
        </>
    )
}
