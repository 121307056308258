import './App.css';
import { Router } from './router/Router';

function App() {
  return (
		<div>
			<Router />
	  	</div>
  );
}

export default App;
